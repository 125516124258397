exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antisocial-js": () => import("./../../../src/pages/antisocial.js" /* webpackChunkName: "component---src-pages-antisocial-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-solution-2-0-js": () => import("./../../../src/pages/solution2.0.js" /* webpackChunkName: "component---src-pages-solution-2-0-js" */),
  "component---src-pages-solution-3-0-js": () => import("./../../../src/pages/solution3.0.js" /* webpackChunkName: "component---src-pages-solution-3-0-js" */)
}

